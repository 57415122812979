/* eslint-disable no-unused-vars */
import {Box, Grid, Link, Tab, Tabs, Typography} from '@material-ui/core'
import classNames from 'classnames'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {
	CyclisHeaderComponent as CyclisHeader,
	TemplateContainerComponent as TemplateContainer,
} from '@components/index'
import {RootState} from '@redux/root-reducer'
import {CommonProps} from '@utils/types'

import ContactTabPanel from './ContactTabPanel'
import useStyles from './style.hook'

/**
 * Contact
 */
const Contact: React.FC<CommonProps> = () => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	const {t} = useTranslation(undefined, {useSuspense: false})

	const {firstName, lastName} = useSelector((state: RootState) => state.auth)

	const [activeTab, setActiveTab] = useState(0)

	const handleActiveTabChange = (
		_e: React.ChangeEvent<{}>,
		newTab: number
	): void => {
		setActiveTab(newTab)
	}

	const ContactTabPanelSales = (): JSX.Element => (
		<ContactTabPanel
			tabIndex={0}
			activeTab={activeTab}
			imageUrl={
				'https://www.cyclis.be/wp-content/uploads/2020/11/Untitled-design-10-1024x1024-1-e1603707423797.jpg'
			}
		>
			<Box className={classes.contactTabPannelInfoWrapper}>
				<Typography variant="h3" className={classes.contactTabPanelName}>
					{t('ContactTabPanelNameSales')}
				</Typography>
				<Box className={classes.contactTabPanelInfoBox}>
					<Box>
						<Typography
							variant="h3"
							className={classes.contactTabPanelDivision}
						>
							{t('ContactTabPanelDivisionSalesBE')}
						</Typography>
						<Typography
							variant="body1"
							className={classes.contactTabPanelPhone}
						>
							<Link href={`tel:${t('ContactTabPanelPhoneSales')}`}>
								{t('ContactTabPanelPhoneSalesBE')}
							</Link>
						</Typography>
						<Typography
							variant="body1"
							className={classes.contactTabPanelEmail}
						>
							<Link
								href={`mailto:${t('ContactTabPanelEmailSales')}`}
								variant={'inherit'}
							>
								{t('ContactTabPanelEmailSalesBE')}
							</Link>
						</Typography>
					</Box>
					<Box>
						<Typography
							variant="h3"
							className={classes.contactTabPanelDivision}
						>
							{t('ContactTabPanelDivisionSalesNL')}
						</Typography>
						<Typography
							variant="body1"
							className={classes.contactTabPanelPhone}
						>
							<Link href={`tel:${t('ContactTabPanelPhoneSales')}`}>
								{t('ContactTabPanelPhoneSalesNL')}
							</Link>
						</Typography>
						<Typography
							variant="body1"
							className={classes.contactTabPanelEmail}
						>
							<Link
								href={`mailto:${t('ContactTabPanelEmailSalesNL')}`}
								variant={'inherit'}
							>
								{t('ContactTabPanelEmailSalesNL')}
							</Link>
						</Typography>
					</Box>
				</Box>
				<Box className={classes.contactTabPanelAvailability}>
					<Typography variant="body1">
						{t('ContactTabPanelAvailability')}
					</Typography>
					{': '}
					<Typography variant="body1">
						{t('ContactTabPanelAvailabilitySales')}
					</Typography>
				</Box>
				<Box className={classes.contactTabPanelFAQ}>
					<Typography variant="body1">
						{t('ContactTabPanelFAQSales')}
					</Typography>{' '}
					<Typography variant="body1">
						<Link href="https://www.cyclis.be/faq/" target="_blank">
							{t('ContactTabPanelFAQ')}
						</Link>
					</Typography>
				</Box>
			</Box>
		</ContactTabPanel>
	)

	const ContactTabPanelAfterCare = (): JSX.Element => (
		<ContactTabPanel
			tabIndex={1}
			activeTab={activeTab}
			imageUrl={
				'https://www.cyclis.be/wp-content/uploads/2020/11/Untitled-design-10-1024x1024-1-e1603707423797.jpg'
			}
		>
			<Box className={classes.contactTabPannelInfoWrapper}>
				<Typography variant="h3" className={classes.contactTabPanelName}>
					{t('ContactTabPanelNameAfterCare')}
				</Typography>
				<Typography variant="body1" className={classes.contactTabPanelEmail}>
					<Link
						href={`mailto:${t('ContactTabPanelEmailAfterCare')}`}
						variant={'inherit'}
					>
						{t('ContactTabPanelEmailAfterCare')}
					</Link>
				</Typography>
				<Typography variant="body1" className={classes.contactTabPanelPhone}>
					<Link href={`tel:${t('ContactTabPanelPhoneAfterCare')}`}>
						{t('ContactTabPanelPhoneAfterCare')}
					</Link>
				</Typography>
				<Box className={classes.contactTabPanelAvailability}>
					<Typography variant="body1">
						{t('ContactTabPanelAvailability')}
					</Typography>
					{': '}
					<Typography variant="body1">
						{t('ContactTabPanelAvailabilityAfterCare')}
					</Typography>
				</Box>
				<Box className={classes.contactTabPanelFAQ}>
					<Typography variant="body1">
						{t('ContactTabPanelFAQAfterCare')}
					</Typography>{' '}
					<Typography variant="body1">
						<Link href="https://www.cyclis.be/faq/" target="_blank">
							{t('ContactTabPanelFAQ')}
						</Link>
					</Typography>
				</Box>
			</Box>
		</ContactTabPanel>
	)

	const ContactTabPanelTechnical = (): JSX.Element => (
		<ContactTabPanel
			tabIndex={2}
			activeTab={activeTab}
			imageUrl={
				'https://www.cyclis.be/wp-content/uploads/2020/11/Untitled-design-10-1024x1024-1-e1603707423797.jpg'
			}
		>
			<Box className={classes.contactTabPannelInfoWrapper}>
				<Typography variant="h3" className={classes.contactTabPanelName}>
					{t('ContactTabPanelNameTechnical')}
				</Typography>
				<Typography variant="body1" className={classes.contactTabPanelEmail}>
					<Link
						href={`mailto:${t('ContactTabPanelEmailTechnical')}`}
						variant={'inherit'}
					>
						{t('ContactTabPanelEmailTechnical')}
					</Link>
				</Typography>
				<Typography variant="body1" className={classes.contactTabPanelPhone}>
					<Link href={`tel:${t('ContactTabPanelPhoneTechnical')}`}>
						{t('ContactTabPanelPhoneTechnical')}
					</Link>
				</Typography>
				<Box className={classes.contactTabPanelAvailability}>
					<Typography variant="body1">
						{t('ContactTabPanelAvailability')}
					</Typography>
					{': '}
					<Typography variant="body1">
						{t('ContactTabPanelAvailabilityTechnical')}
					</Typography>
				</Box>
				<Box className={classes.contactTabPanelFAQ}>
					<Typography variant="body1">
						{t('ContactTabPanelFAQTechnical')}
					</Typography>{' '}
					<Typography variant="body1">
						<Link href="https://www.cyclis.be/faq/" target="_blank">
							{t('ContactTabPanelFAQ')}
						</Link>
					</Typography>
				</Box>
			</Box>
		</ContactTabPanel>
	)

	const ContactTabPanelBilling = (): JSX.Element => (
		<ContactTabPanel
			tabIndex={3}
			activeTab={activeTab}
			imageUrl={
				'https://www.cyclis.be/wp-content/uploads/2020/11/Untitled-design-10-1024x1024-1-e1603707423797.jpg'
			}
		>
			<Box className={classes.contactTabPannelInfoWrapper}>
				<Typography variant="h3" className={classes.contactTabPanelName}>
					{t('ContactTabPanelNameBilling')}
				</Typography>
				<Typography variant="body1" className={classes.contactTabPanelEmail}>
					<Link
						href={`mailto:${t('ContactTabPanelEmailBilling')}`}
						variant={'inherit'}
					>
						{t('ContactTabPanelEmailBilling')}
					</Link>
				</Typography>
				<Typography variant="body1" className={classes.contactTabPanelPhone}>
					<Link href={`tel:${t('ContactTabPanelPhoneBilling')}`}>
						{t('ContactTabPanelPhoneBilling')}
					</Link>
				</Typography>
				<Box className={classes.contactTabPanelAvailability}>
					<Typography variant="body1">
						{t('ContactTabPanelAvailability')}
					</Typography>
					{': '}
					<Typography variant="body1">
						{t('ContactTabPanelAvailabilityBilling')}
					</Typography>
				</Box>
				<Box className={classes.contactTabPanelFAQ}>
					<Typography variant="body1">
						{t('ContactTabPanelFAQBilling')}
					</Typography>{' '}
					<Typography variant="body1">
						<Link href="https://www.cyclis.be/faq/" target="_blank">
							{t('ContactTabPanelFAQ')}
						</Link>
					</Typography>
				</Box>
			</Box>
		</ContactTabPanel>
	)

	const ContactTabPanelOther = (): JSX.Element => (
		<ContactTabPanel
			tabIndex={4}
			activeTab={activeTab}
			imageUrl={
				'https://www.cyclis.be/wp-content/uploads/2020/11/Untitled-design-10-1024x1024-1-e1603707423797.jpg'
			}
		>
			<Box className={classes.contactTabPannelInfoWrapper}>
				<Typography variant="h3" className={classes.contactTabPanelName}>
					{t('ContactTabPanelNameOther')}
				</Typography>
				<Typography variant="body1" className={classes.contactTabPanelEmail}>
					<Link
						href={`mailto:${t('ContactTabPanelEmailOther')}`}
						variant={'inherit'}
					>
						{t('ContactTabPanelEmailOther')}
					</Link>
				</Typography>
				<Typography variant="body1" className={classes.contactTabPanelPhone}>
					<Link href={`tel:${t('ContactTabPanelPhoneOther')}`}>
						{t('ContactTabPanelPhoneOther')}
					</Link>
				</Typography>
				<Box className={classes.contactTabPanelAvailability}>
					<Typography variant="body1">
						{t('ContactTabPanelAvailability')}
					</Typography>
					{': '}
					<Typography variant="body1">
						{t('ContactTabPanelAvailabilityOther')}
					</Typography>
				</Box>
				<Box className={classes.contactTabPanelFAQ}>
					<Typography variant="body1">
						{t('ContactTabPanelFAQOther')}
					</Typography>{' '}
					<Typography variant="body1">
						<Link href="https://www.cyclis.be/faq/" target="_blank">
							{t('ContactTabPanelFAQ')}
						</Link>
					</Typography>
				</Box>
			</Box>
		</ContactTabPanel>
	)

	return (
		<TemplateContainer className={classes.root}>
			<CyclisHeader customerName={`${firstName} ${lastName}`} />
			<Box className={classes.containerWrapper}>
				<Grid container spacing={8}>
					<Grid item xs={1} />
					<Grid item xs={6}>
						<Typography variant="h3" className={classes.title}>
							{t('ContactTitle')}
						</Typography>
						<Typography variant="h3" className={classes.subtitle}>
							{t('ContactSubtitle')}
						</Typography>

						{/* <Tabs
							className={classes.contactTabs}
							value={activeTab}
							onChange={handleActiveTabChange}
						>
							<Tab
								id="contact-tab-0"
								aria-controls="contact-tab-panel-0"
								label={t('ContactTabTitleSales')}
							/>
							<Tab
								id="contact-tab-1"
								aria-controls="contact-tab-panel-1"
								label={t('ContactTabTitleAfterCare')}
							/>
							<Tab
								id="contact-tab-2"
								aria-controls="contact-tab-panel-2"
								label={t('ContactTabTitleTechnical')}
							/>
							<Tab
								id="contact-tab-3"
								aria-controls="contact-tab-panel-3"
								label={t('ContactTabTitleBilling')}
							/>
							<Tab
								id="contact-tab-4"
								aria-controls="contact-tab-panel-4"
								label={t('ContactTabTitleOther')}
							/>
						</Tabs> */}
						<ContactTabPanelSales />
						{/* <ContactTabPanelAfterCare />
						<ContactTabPanelTechnical />
						<ContactTabPanelBilling />
						<ContactTabPanelOther /> */}

						<Typography variant="h3" className={classes.ContactTilesTitle}>
							{t('ContactTilesFindUs')}
						</Typography>
						<Box className={classes.contactTiles}>
							<Link
								href="https://www.google.com/maps/place/Hendrik+van+Veldekesingel+39,+3500+Hasselt/@50.9353777,5.3146862,17z/data=!3m1!4b1!4m5!3m4!1s0x47c12231aaf63dd1:0x87b7e443e9bc4c6d!8m2!3d50.9353777!4d5.3168749"
								target="_blank"
								className={classNames(
									classes.contactTile,
									classes.contactTileOffice
								)}
							>
								<img src="/images/contact-tile-office-icon.png" />
								<Box>
									<Typography variant="h3">
										{t('ContactTileOfficeTitle')}
									</Typography>
									<Typography variant="body1">
										{t('ContactTileOfficeBody')}
									</Typography>
								</Box>
							</Link>
							<Link
								href="https://www.facebook.com/cyclisbikelease"
								target="_blank"
								className={classNames(
									classes.contactTile,
									classes.contactTileFacebook
								)}
							>
								<img src="/images/contact-tile-facebook-icon.png" />
								<Box>
									<Typography variant="h3">
										{t('ContactTileFacebookTitle')}
									</Typography>
									<Typography variant="body1">
										{t('ContactTileFacebookBody')}
									</Typography>
								</Box>
							</Link>

							<Link
								href="https://www.instagram.com/cyclisbikelease/"
								target="_blank"
								className={classNames(
									classes.contactTile,
									classes.contactTileInstagram
								)}
							>
								<img src="/images/contact-tile-instagram-icon.png" />
								<Box>
									<Typography variant="h3">
										{t('ContactTileInstagramTitle')}
									</Typography>
									<Typography variant="body1">
										{t('ContactTileInstagramBody')}
									</Typography>
								</Box>
							</Link>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</TemplateContainer>
	)
}

Contact.propTypes = {}

export default Contact
