import {makeStyles} from '@material-ui/core/styles'

import {LARGE_GUTTER_SIZE} from '@styles/mui-theme'

/**
 * Component-scoped styles which should be used as a hook
 * inside Contact functional component
 */

const useStyles = makeStyles((theme) => ({
	root: {
		background: 'url(/images/contact-background.png)',
		// -68px is CyclisHeader height
		backgroundPosition: `top -68px right ${LARGE_GUTTER_SIZE}px`,
		backgroundSize: 'calc(100vw / 12 * 4)',
		backgroundRepeat: 'no-repeat',
		backgroundColor: '#edf2f8',
	},
	// TODO: Refactor logo to mobile first and adjust positioning to match design
	logo: {
		height: theme.spacing(40 / 8),
		width: theme.spacing(112 / 8),
		position: 'absolute',
		marginTop: theme.spacing(35 / 8),
		marginLeft: theme.spacing(40 / 8),
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(32 / 8),
			marginLeft: theme.spacing(66 / 8),
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(20 / 8),
			marginLeft: theme.spacing(32 / 8),
		},
	},
	containerWrapper: {
		marginTop: theme.spacing(56 / 8),
	},
	title: {
		color: '#08080d',
		fontSize: 36,
		fontWeight: 700,
		lineHeight: 1,
		letterSpacing: -0.77,
		marginBottom: theme.spacing(16 / 8),
	},
	subtitle: {
		fontSize: 24,
		fontWeight: 700,
		lineHeight: 'normal',
		letterSpacing: '-0.33px',
		color: '#7e7e80',
		marginBottom: theme.spacing(40 / 8),
	},
	contactTabs: {
		margin: theme.spacing(0, 0, 35 / 8),
		overflow: 'visible',

		'& .MuiTab-root': {
			borderRadius: 8,
			padding: theme.spacing(16 / 8, 24 / 8),
			minWidth: 'unset',

			fontFamily: 'Montserrat',
			fontSize: 16,
			fontWeight: 700,
			lineHeight: 'normal',
			textTransform: 'none',
			color: '#222d39',
			opacity: 1,

			'&.Mui-selected': {
				color: theme.palette.primary.main,
				background: theme.palette.common.white,
				boxShadow: '0 16px 32px 0 rgba(82, 79, 162, 0.32)',
			},
		},
		'& .MuiTabs-scroller': {
			overflow: 'visible !important',
		},
		'& .MuiTabs-indicator': {
			display: 'none',
		},
	},
	contactTabPannelInfoWrapper: {
		width: 'calc(65% + 2 * 24px)',
	},
	contactTabPanelName: {
		color: '#08080d',
		fontSize: 16,
		fontWeight: 700,
		letterSpacing: -0.22,
		lineHeight: 'normal',
		marginBottom: theme.spacing(10 / 8),
	},
	contactTabPanelInfoBox: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	contactTabPanelDivision: {
		color: '#08080d',
		fontSize: 16,
		fontWeight: 700,
		letterSpacing: -0.22,
		lineHeight: 'normal',
	},
	contactTabPanelEmail: {
		color: theme.palette.primary.main,
		fontSize: 16,
		fontWeight: 600,
		letterSpacing: 0.19,
		lineHeight: 'normal',
		textDecoration: 'underline',
		marginBottom: theme.spacing(16 / 8),
	},
	contactTabPanelPhone: {
		color: '#08080d',
		fontSize: 16,
		fontWeight: 500,
		letterSpacing: 0.19,
		lineHeight: 'normal',
		marginBottom: theme.spacing(4 / 8),

		'& a': {
			color: 'inherit',
		},
	},
	contactTabPanelAvailability: {
		position: 'relative',
		padding: theme.spacing(12 / 8, 16 / 8, 12 / 8, 38 / 8),
		marginBottom: theme.spacing(32 / 8),
		borderRadius: 8,
		backgroundColor: '#f2f1f8',

		'&::before': {
			content: '""',
			position: 'absolute',
			top: 16,
			bottom: 16,
			left: 20,
			width: 2,
			backgroundColor: theme.palette.primary.main,
			borderRadius: 1,
		},

		'& p.MuiTypography-root': {
			display: 'inline',
			color: '#7e7e80',
			fontWeight: 500,
			fontSize: 14,

			'&:first-of-type': {
				color: theme.palette.common.black,
				fontWeight: 700,
			},
		},
	},
	contactTabPanelFAQ: {
		'& p.MuiTypography-root': {
			display: 'inline',
			color: theme.palette.primary.main,
			fontWeight: 500,
			fontSize: 16,
			lineHeight: 'normal',
			letterSpacing: 0.19,

			'&:first-of-type': {
				color: '#7e7e80',
				fontWeight: 500,
			},
		},
	},
	contactTiles: {
		display: 'flex',
		justifyContent: 'space-between',
		columnGap: LARGE_GUTTER_SIZE * 2,
	},
	ContactTilesTitle: {
		color: '#08080d',
		fontSize: 24,
		fontWeight: 700,
		letterSpacing: -0.33,
		lineHeight: 'normal',
		marginBottom: theme.spacing(16 / 8),
	},
	contactTile: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		height: 210,
		borderRadius: 8,
		padding: theme.spacing(24 / 8, 20 / 8, 20 / 8),
		textAlign: 'center',
		color: theme.palette.common.white,

		'& .MuiTypography-h3': {
			fontSize: 16,
			fontWeight: 700,
			lineHeight: 'normal',
			letterSpacing: 'normal',
			textDecoration: 'underline',
			marginBottom: theme.spacing(8 / 8),
		},
		'& .MuiTypography-body1': {
			fontSize: 16,
			fontWeight: 500,
			lineHeight: 'normal',
			letterSpacing: 0.19,
		},
		'&:hover': {
			textDecoration: 'none',
		},
	},
	contactTileOffice: {
		backgroundColor: '#91a1bb',

		'& img': {
			height: 80,
		},
	},
	contactTileFacebook: {
		backgroundColor: theme.palette.primary.main,
		paddingTop: theme.spacing(40 / 8),

		'& img': {
			width: 46,
		},
	},
	contactTileInstagram: {
		backgroundColor: '#fcbb75',
		paddingTop: theme.spacing(40 / 8),

		'& img': {
			width: 46,
		},
	},
}))

export default useStyles
