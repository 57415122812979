import {Box} from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

import {CommonProps} from '@utils/types'

import useStyles from './style.hook'

/**
 * ContactTabPanel
 */
interface ContactTabPanelProps extends CommonProps {
	tabIndex: number
	activeTab: number
	imageUrl?: string
}

const ContactTabPanel: React.FC<ContactTabPanelProps> = ({
	tabIndex,
	activeTab,
	imageUrl,
	children,
}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	return (
		<Box
			className={classes.contactTabPanel}
			id={`contact-tab-panel-${tabIndex}`}
			aria-labelledby={`contact-tab-${tabIndex}`}
			role="tabpanel"
			hidden={activeTab !== tabIndex}
			style={{backgroundImage: `url(${imageUrl})`}}
		>
			{children}
		</Box>
	)
}

ContactTabPanel.propTypes = {
	tabIndex: PropTypes.number.isRequired,
	activeTab: PropTypes.number.isRequired,
	imageUrl: PropTypes.string,
	children: PropTypes.any,
}

export default ContactTabPanel
