import {makeStyles} from '@material-ui/core/styles'

/**
 * Component-scoped styles which should be used as a hook
 * inside ContactTabPanel functional component
 */

const useStyles = makeStyles((theme) => ({
	contactTabPanel: {
		backgroundPosition: 'right -20px bottom',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 275,
		backgroundColor: theme.palette.common.white,
		borderRadius: 8,
		padding: theme.spacing(32 / 8, 24 / 8),
		paddingRight: 0,
		marginRight: theme.spacing(30 / 8),
		marginBottom: theme.spacing(40 / 8),
	},
}))

export default useStyles
